import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import remarkGfm from "remark-gfm";

import PrivacyPolicy from "./PrivacyPolicy.md";

const PageContainer = styled.div`
  padding: 40px 20px;
  max-width: 980px;
  margin: 0 auto;

  a {
    text-decoration: none;
    color: #06c;

    :hover {
      text-decoration: underline;
    }
  }

  a[href^="mailto:"] {
    text-decoration: none;
    color: inherit;
  }

  h2,
  h3 {
    text-align: center;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 20px;
  }

  ul {
    margin-bottom: 20px;
  }

  ul li {
    margin-left: 20px;
    margin-bottom: 20px;
  }

  table {
    border-spacing: 0px;
    border-collapse: separate;
    padding: 0px;
    margin: 0px;
    margin-bottom: 20px;
    border: 1px solid #ccc;

    tr th {
      padding: 5px;
      border-bottom: 1px solid #ccc;
      border-right: 1px solid #ccc;
    }

    tr td {
      padding: 10px;
      border-bottom: 1px solid #ccc;
      border-right: 1px solid #ccc;
      vertical-align: top;
    }

    tr th:last-child,
    tr td:last-child {
      border-right: none;
    }
    tr:last-child td {
      border-bottom: none;
    }
  }
`;

export default function PrivacyPolicyPage() {
  const [content, setContent] = useState();

  useEffect(() => {
    fetch(PrivacyPolicy)
      .then((response) => response.text())
      .then((content) => setContent(content));
  }, []);

  return (
    <PageContainer>
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          a: ({ node, ...props }) => (
            <a {...props} rel="noopener noreferrer" target="_blank">
              {props.children}
            </a>
          ),
        }}
      >
        {content}
      </ReactMarkdown>
    </PageContainer>
  );
}
