import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import HamburgerImage from '../../images/menu_icon.svg'
import SmallLogoImage from '../../images/small-logo-image.svg'
import { Nav,
     NavbarContainer, 
     NavLogoWrap, 
     MobileIcon, NavLinkContainer, NavHamburger, NavItem } from './NavbarElements' 
import NavbarTwitterButton from './NavbarTwitterButton'
import NavbarBlogButton from './NavbarBlogButton'

const Navbar = ({ isOpen, toggle }) => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [shouldShowIcon, setShouldShowIcon] = useState(false);
  const navigate = useNavigate();

  var careersButton;

  function goToCareersPage() {
    navigate("/#careers");
  }  

  function goToHomePage() {
      navigate("/");
    } 

  useEffect(() => {
      if (window.matchMedia("(max-width: 600px)").matches) {
        setIsDesktop(false);
      } else {
        setIsDesktop(true);
      }
    }, []);

    useEffect(() => {   
      window.addEventListener("scroll", listenToScroll);
      return () => 
         window.removeEventListener("scroll", listenToScroll); 
    }, [])

    const getOffset = (element) => {
      const rect = element?.getBoundingClientRect();
      const scrollTop = window.pageYOffset ||  document.documentElement.scrollTop;
      return rect?.top + scrollTop;
    };

    const listenToScroll = () => {
      const heightToHideFrom = getOffset(document.getElementById("careers"));
      const winScroll = document.body.scrollTop || 
          document.documentElement.scrollTop;
         
      if (winScroll >= heightToHideFrom - 100) { 
        setShouldShowIcon(true);
      } else {
        setShouldShowIcon(true);
      }  
    };
  
  return (
    <>
    <Nav>
        <NavbarContainer>
        <NavLogoWrap onClick={() => goToHomePage()}>
          <MobileIcon src={SmallLogoImage} alt="TipTop Logo" />
        </NavLogoWrap>
        <NavLinkContainer>
        <NavItem onClick={() => goToCareersPage()}>Careers</NavItem>
        <NavbarBlogButton />
        <NavbarTwitterButton />
        </NavLinkContainer>
        <NavHamburger isOpen={isOpen} onClick={toggle}>
        <img src={ HamburgerImage } alt="Menu" />
        </NavHamburger>
        </NavbarContainer>
    </Nav>
    </>
  )
}

export default Navbar