export const about = {
    text: "We’re inviting experienced front end engineers to join the TipTop founding team with backgrounds in engineering, data science & product. Together we will be working on systems at the intersection of fiat and crypto. Are you a curious thinker who likes to solve complex challenges in a rewarding environment where your contributions are valued and have a direct impact?"
};

export const howToApply = {
    text: "Send us an email with a copy of your resume or LinkedIn, your GitHub (if you’ve got one), and any projects you think we should see."
}

export const job1 = {
    background_image_id: "background_image_1",
    text_wrapper_id: "text_wrapper_1",
    job_id: '1',
    title: "Machine Learning & Data Infrastructure",
    first_subtitle: "Full-Time",
    second_subtitle: "Remote",
    light_background: false,
    about: `We’re inviting individuals with a deep knowledge in machine learning and data infrastructure to join the TipTop founding team with backgrounds in engineering, data science & product. Together we will be working on systems at the intersection of fiat and crypto. Are you a curious thinker who likes to solve complex challenges in a rewarding environment where your contributions are valued and have a direct impact?`,
    youWill: [
    <ul>
        <li> Work on complex challenges building systems in the applied machine learning space</li>
        <li> Build reliable data services and robust ingestion pipelines for both structured and unstructured data sources</li>
        <li> Design and ship ETL pipelines that transform, aggregate, normalize, and master messy data</li>
        <li> Define data models for optimal storage with a keen eye for model development, analysis, and optimization over time</li>
        <li> Develop, train, and deploy models in the pricing, risk, and prediction space</li>
        <li> Contribute to the development of consumer facing experimentation & risk platforms</li>
        <li> Have the opportunity to build and lead a team</li>
    </ul>
        ],
    youHave: [
            <ul>
            <li>7+ years of experience as a software engineer architecting, building, launching and maintaining end-to-end ML systems from whiteboard to production</li>
            <li>Demonstrated ability turning business and product ideas into engineering solutions. You can go from zero to one</li>
            <li>Strong knowledge of statistics and machine learning</li>
            <li>Strong programming experience in Python | SQL</li>
            <li>Familiarity with modern data stores, pipeline & orchestration tools, and ML frameworks</li>
            </ul>
        ],
    evenBetter: [
            <ul>
            <li>Experience building experimentation frameworks</li>
            <li>Experience in quantitative finance and forecasting/prediction models</li>
            <li>An advanced degree (M.S., PhD.) in Mathematics, Statistics, Computer Science, Quantitative Finance, Physics, or a related technical field</li>
            <li>Passion for building, bias for action, and are excited by uncertainty</li>
            <li>You are autonomous & adaptable  –  own the work, move fast, react quickly</li>
            </ul>
        ],
    tech: [
            <ul>
            <li>Python</li>
            <li>Go</li>
            <li>SQL/Postgres</li>
            <li>Dataflow / Airflow</li>
            <li>DBT</li>
            <li>TensorFlow/PyTorch/Spark</li>
            <li>GCP</li>
            </ul>
        ],
    howToApply: howToApply.text,
};

export const job2 = {
    background_image_id: "background_image_2",
    text_wrapper_id: "text_wrapper_2",
    job_id: '2',
    title: "Product Designer",
    first_subtitle: "Full-Time",
    second_subtitle: "Remote",
    light_background: true,
    about: `We’re inviting individuals with a deep knowledge in product design and user interactions to join the TipTop founding team with backgrounds in engineering, data science & product. Together we will be working on consumer facing systems at the intersection of fiat and crypto. Are you a curious designer armed with the experience to create new consumer products and interactions free from dogma?`,
    youWill: [
    <ul>
    <li>Design consumer and business facing apps and interactions</li>
    <li>Create, design and contribute to web and mobile products</li>
    <li>Define internal and external product design systems</li>
    <li>Directly work with eng and data teams on design implementations</li>
    <li>Have the opportunity to build and lead a team</li>
    </ul>
        ],
    youHave: [
            <ul>
            <li>5+ years of professional product design experience</li>
            <li>A portfolio of product design work on which you were the primary designer</li>
            <li>Demonstrated ability turning business and product ideas into actionable product designs. You can go from zero to one</li>
            <li>The ability to take light direction and rapidly convert into design explorations, prototypes, and mocks</li>
            <li>Experience working closely and humbly with product & engineering to reach the right design implementations after iteration and experimentation</li>
            </ul>
        ],
    evenBetter: [
            <ul>
            <li>Curious and experienced in building systems</li>
            <li>Track record of shipping high-quality, thoughtfully designed products and features at scale</li>
            <li>Experience with modern design tools (Figma, Sketch, Invision)</li>
            <li>Passion for building, bias for action, and are excited by uncertainty</li>
            </ul>
        ],
    howToApply: `Send us an email with a copy of your resume or LinkedIn, your portfolio, and any projects you think we should see.`
};

export const job3 = {
    background_image_id: "background_image_3",
    text_wrapper_id: "text_wrapper_3",
    job_id: '3',
    title: "Blockchain Engineer",
    first_subtitle: "Full-Time",
    second_subtitle: "Remote",
    light_background: false,
    about: `We’re inviting curious individuals with a deep interest in blockchain technologies and decentralized finance to join the TipTop founding team with backgrounds in engineering, data science & product. Together we will be working on systems at the intersection of fiat and crypto. Are you a curious thinker who likes to lead the design and architecture of core protocols and blockchain technologies?`,
    youWill: [
        <ul>
        <li>Research, develop, and test various blockchain technologies</li>
        <li>Lead the design and architecture of the core protocols and tokens</li>
        <li>Build the protocol components that integrate across different blockchain systems</li>
        <li>Set technical and cultural standards for the blockchain team</li>
        <li>Be involved in the current blockchain community</li>
        </ul>
        ],
    youHave: [
            <ul>
            <li>4+ years of software engineering experience</li>
            <li>Strong CS fundamentals</li>
            <li>Led the design of impactful and complex architectures</li>
            <li>Interest and ability to pick up new technologies quickly</li>
            <li>Experience with C, C++, systems programming, mobile, embedded</li>
            <li>Experience developing and deploying smart contracts and blockchain protocols</li>
            <li>Familiarity with Rust, Solidity, TypeScript and/or Golang programming languages</li>
            <li>Ability to see complex problems as solvable challenges</li>
            </ul>
        ],
    evenBetter: [
            <ul>
            <li>Experience with open source projects</li>
            <li>Community involvement in bitcoin and decentralized finance</li>
            </ul>
        ],
    howToApply: howToApply.text,
};

export const job4 = {
    background_image_id: "background_image_4",
    text_wrapper_id: "text_wrapper_4",
    job_id: '4',
    title: "Backend Engineer",
    first_subtitle: "Full-Time",
    second_subtitle: "Remote",
    light_background: true,
    about: `We’re inviting experienced backend engineers to join the TipTop founding team with backgrounds in engineering, data science & product. Together we will be working on systems at the intersection of fiat and crypto. Are you a curious thinker who likes to solve complex challenges in a rewarding environment where your contributions are valued and have a direct impact?`,
    youWill: [
    <ul>
    <li>Work on complex challenges building software systems for products and verticals that don’t yet exist</li>
    <li>Build scalable core services that power the platform and both internal and external endpoints</li>
    <li>Partner and collaborate closely with product designers, blockchain, infrastructure, and machine learning teams</li>
    <li>Contribute to the development of consumer facing experimentation & risk platforms</li>
    <li>Have the opportunity to build and lead a team</li>
    </ul>
        ],
    youHave: [
            <ul>
            <li>5+ years of experience as a software engineer architecting, building, launching, and maintaining end-to-end services from whiteboard to production</li>
            <li>Demonstrated ability turning business and product ideas into engineering solutions. You can go from zero to one</li>
            <li>Strong CS fundamentals and programming experience in Go and Python</li>
            <li>A desire to experiment, test assumptions, and challenge hypotheses rapidly</li>
            <li>The ability to see complex problems as solvable challenges</li>
            </ul>
        ],
    evenBetter: [
            <ul>
            <li>Passion for building, bias for action, and are excited by uncertainty</li>
            <li>You are autonomous & adaptable  –  own the work, move fast, react quickly</li>
            <li>Interest and ability to pick up new technologies quickly</li>
            </ul>
        ],
    tech: [
            <ul>
            <li>Go</li>
            <li>Python</li>
            <li>SQL/Postgres</li>
            <li>GCP</li>
            <li>Docker</li>
            <li>Kubernetes</li>
            </ul>
        ],
    howToApply: `Send us an email with a copy of your resume or LinkedIn, your GitHub, and any projects you think we should see`,
};

export const job5 = {
    background_image_id: "background_image_5",
    text_wrapper_id: "text_wrapper_5",
    job_id: '5',
    title: "Frontend Engineer",
    first_subtitle: "Full-Time",
    second_subtitle: "Remote",
    light_background: false,
    about: `We’re inviting experienced frontend engineers to join the TipTop founding team with backgrounds in engineering, data science & product. Together we will be working on systems at the intersection of fiat and crypto. Are you a curious thinker who likes to solve complex challenges in a rewarding environment where your contributions are valued and have a direct impact?`,
    youWill: [
            <ul>
            <li>Work on complex challenges building software systems for products and verticals that don’t yet exist</li>
            <li>Lead the design and architecture of core consumer and partner facing stacks</li>
            <li>Test designs rapidly and iterate based on data</li>
            <li>Partner and collaborate closely with product designers, blockchain, infrastructure, and machine learning teams</li>
            <li>Have the opportunity to build and lead a team</li>
            </ul>
        ],
    youHave: [
            <ul>
            <li>4+ years of experience as a software engineer architecting, building, launching, and maintaining front end services from whiteboard to production</li>
            <li>Demonstrated ability turning business and product ideas into engineering solutions, you can go from zero to one</li>
            <li>Strong CS fundamentals and programming experience in Javascript, React, HTML & CSS</li>
            <li>A desire to experiment, test assumptions, and challenge hypotheses rapidly</li>
            <li>The ability to see complex problems as solvable challenges</li>
            </ul>
        ],
    evenBetter: [
            <ul>
            <li>Passion for building, bias for action, and are excited by uncertainty</li>
            <li>You are autonomous & adaptable  –  own the work, move fast, react quickly</li>
            <li>Interest and ability to pick up new technologies quickly</li>
            </ul>
        ],
    tech: [
            <ul>
            <li>Javascript</li>
            <li>HTML/CSS</li>
            <li>React</li>
            <li>Python</li>
            <li>SQL/Postgres</li>
            <li>GCP</li>
            <li>Docker</li>
            <li>Kubernetes</li>
            </ul>
        ],
    howToApply: `Send us an email with a copy of your resume or LinkedIn, your GitHub (if you’ve got one), and any projects you think we should see.`
};