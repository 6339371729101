import "./App.scss";
import { Routes, Route } from "react-router-dom";
import React, { useState } from "react";

import Home from "./pages";
import CareerDetailsPage from "./CareerDetails";
import { job1, job2, job3, job4, job5 } from "./Data";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TOS from "./pages/TOS";

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Routes>
      <Route path="/" element={<Home isOpen={isOpen} toggle={toggle} />} />
      <Route
        path="jobs/1"
        element={
          <CareerDetailsPage isOpen={isOpen} toggle={toggle} {...job1} />
        }
      />
      <Route
        path="jobs/2"
        element={
          <CareerDetailsPage isOpen={isOpen} toggle={toggle} {...job2} />
        }
      />
      <Route
        path="jobs/3"
        element={
          <CareerDetailsPage isOpen={isOpen} toggle={toggle} {...job3} />
        }
      />
      <Route
        path="jobs/4"
        element={
          <CareerDetailsPage isOpen={isOpen} toggle={toggle} {...job4} />
        }
      />
      <Route
        path="jobs/5"
        element={
          <CareerDetailsPage isOpen={isOpen} toggle={toggle} {...job5} />
        }
      />
      <Route
        path="jobs/5"
        element={
          <CareerDetailsPage isOpen={isOpen} toggle={toggle} {...job5} />
        }
      />
      <Route path="legal/terms" element={<TOS />} />
      <Route path="legal/privacy" element={<PrivacyPolicy />} />
    </Routes>
  );
}

export default App;
