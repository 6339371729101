import styled from 'styled-components'

export const SidebarTwitterButtonStyle = styled.a`
    text-decoration: none;
    align-self: right;
    font-family: "BasicSans-Bold";
    font-weight: 700;
    color: black;
    font-size: 24px;
    line-height: 50px;
    cursor: pointer;
`;