import React from "react";
import { SidebarTwitterButtonStyle } from "./SidebarTwitterButtonStyle";

const SidebarTwitterButton = () => {
    return (
      <SidebarTwitterButtonStyle href={`https://twitter.com/tiptopxyz`}>Follow</SidebarTwitterButtonStyle>
    );
  };

export default SidebarTwitterButton;
