import React from "react";

const FooterButtonTwitter = () => {
    return (
      <a style={{
        fontFamily: "BasicSans-SemiBold",
        textDecoration: "none",
        display: "flex", 
        justifyContent: "center",
        alignItems: "center",
        fontStyle: "normal",
        fontWeight: "600",
      fontSize: "18px",
      lineHeight: "28px",
      textAlign: "center",
      color: "white",
      cursor: "pointer"
      }} href={`https://twitter.com/tiptopxyz`}>@tiptopxyz</a>
    );
  };

export default FooterButtonTwitter;
