import React from "react";
import { SidebarTwitterButtonStyle } from "./SidebarTwitterButtonStyle";

const SidebarBlogButton = () => {
  return (
    <SidebarTwitterButtonStyle href={`https://blog.tiptop.xyz`}>Blog</SidebarTwitterButtonStyle>
  );
};

export default SidebarBlogButton;
