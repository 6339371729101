import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";

import TOS from "./TOS.md";

const PageContainer = styled.div`
  padding: 40px 20px;
  max-width: 980px;
  margin: 0 auto;

  a {
    text-decoration: none;
    color: #06c;

    :hover {
      text-decoration: underline;
    }
  }

  h2,
  h3 {
    text-align: center;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 20px;
  }

  ul {
    margin-bottom: 20px;
  }

  ul li {
    margin-left: 20px;
  }

  ol li {
    margin-left: 30px;
  }
`;

export default function TOSPage() {
  const [content, setContent] = useState();

  useEffect(() => {
    fetch(TOS)
      .then((response) => response.text())
      .then((content) => setContent(content));
  }, []);

  return (
    <PageContainer>
      <ReactMarkdown
        components={{
          a: ({ node, ...props }) => (
            <a {...props} rel="noopener noreferrer" target="_blank">
              {props.children}
            </a>
          ),
        }}
      >
        {content}
      </ReactMarkdown>
    </PageContainer>
  );
}
