import React, { useLayoutEffect, useEffect } from 'react'
import DownArrowImage from '../../images/down-rounded-arrow.svg'
import { ButtonWrap } from '../ButtonElements'
import { CareerContainer, InfoWrapper, 
  Header, ImgUpArrow, 
  BackgroundImage, BackgroundImageBehindGrid } from './CareerElements';
import CareerCell from './CareerCell';
import { job1, job2, job3, job4, job5 } from '../../Data';

const CareerSection = () => {

    const [dimensions, setDimensions] = React.useState({ 
      height: null,
      width: null
    })

    useEffect(() => {
      function handleResize() {
        let elem = document.getElementById("text_wrapper_2").getBoundingClientRect()
        const width = elem.width + 20;
        const height = elem.height;
        setDimensions({ height: height, width: width })
            for (let index = 0; index < 5; index++) {
              let gridName = index + 1
              let backgroundElem = document.getElementById("grid" + gridName.toString());
               backgroundElem.style.width = width.toString() + 'px';
                backgroundElem.style.height = height.toString() + 'px';
            }
      }
      window.addEventListener('resize', handleResize)
    }, []);

    useLayoutEffect(() => {
      let elem = document.getElementById("text_wrapper_2").getBoundingClientRect()
      const width = elem.width + 20;
      const height = elem.height;
      setDimensions({ height: height, width: width })
      for (let index = 0; index < 5; index++) {
        let gridName = index + 1
        let backgroundElem = document.getElementById("grid" + gridName.toString());
        backgroundElem.style.width = width.toString() + 'px';
        backgroundElem.style.height = height.toString() + 'px';
      }
    }, []);

  return (
    <>
        <CareerContainer id="careers">
        <BackgroundImage>
        <InfoWrapper>
            <Header>Careers</Header>
            <BackgroundImageBehindGrid id="grid1" light_background={job1.light_background}/>
            <CareerCell {...job1} />

            <BackgroundImageBehindGrid id="grid2" light_background={job2.light_background}/>
            <CareerCell {...job2} />

            <BackgroundImageBehindGrid id="grid3" light_background={job3.light_background}/>
            <CareerCell {...job3} />

            <BackgroundImageBehindGrid id="grid4" light_background={job4.light_background}/>
            <CareerCell {...job4} />

            <BackgroundImageBehindGrid id="grid5" light_background={job5.light_background}/>
            <CareerCell {...job5} />
        </InfoWrapper>
        </BackgroundImage>
        <ButtonWrap 
            to="home" 
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}>
            <ImgUpArrow src={DownArrowImage} alt="Scroll Up" />
        </ButtonWrap>
        </CareerContainer>
    </>
  )
}

export default CareerSection