import React from 'react'
import { FooterContainer, Copyright } from './FooterElements'
import FooterButtonTwitter from './FooterButtonTwitter';

const FooterSection = () => {
    return (
        <>
        <FooterContainer>
        <FooterButtonTwitter/>
        <Copyright>© 2023 TipTop Labs, Inc.</Copyright>
        </FooterContainer>
        </>
    )
  }
  

  export default FooterSection