import styled from 'styled-components'
import {Link} from 'react-scroll'

export const ButtonWrap = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    width: 100vw;
`
